@use '@angular/material' as mat;
@use 'sass:map';
@import 'style/base/typography';

// WISE Default theme

// Material grey color palette for reference
$mat-grey: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161
);

// define Angular Material theme colors
$default-primary: (
  50 : #f5f5f5,
  100 : #e9e9e9,
  200 : #d9d9d9,
  300 : #c4c4c4,
  400 : #9d9d9d,
  500 : #7b7b7b,
  600 : #555555,
  700 : #434343,
  800 : #262626,
  900 : #000000,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
  )
);

$default-accent: (
  50 : #f5f5f5,
  100 : #e9e9e9,
  200 : #d9d9d9,
  300 : #c4c4c4,
  400 : #9d9d9d,
  500 : #7b7b7b,
  600 : #555555,
  700 : #434343,
  800 : #262626,
  900 : #000000,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
  )
);

$default-theme-primary: mat.m2-define-palette($default-primary, 900);
$default-theme-accent: mat.m2-define-palette($default-accent, 900);
$default-theme-warn: mat.m2-define-palette(mat.$m2-red-palette, 500);

// create Angular Material theme
$default: mat.m2-define-light-theme((
  color: (
    primary: $default-theme-primary,
    accent: $default-theme-accent,
    warn: $default-theme-warn,
  ),
  typography: $wise-typography,
));

$default-theme-background: (
  status-bar: white,
  app-bar:    white,
  background: white,
);

$default-theme: map.deep-merge($default, (
  'color': (
    'background': $default-theme-background,
  ),
));

// get theme color variables
$foreground: map.get($default-theme, foreground);
$background: map.get($default-theme, background);

// create colors object (including additional colors)
$default-colors: (
  'accent': mat.m2-get-color-from-palette($default-theme-accent, 900),
  'accent-1': black,
  'accent-2': black,
  'app-bg': white,
  'container': rgba(255, 255, 255, 0.7),
  'control-bg': white,
  'control-bg-active': white,
  'disabled-text': mat.m2-get-color-from-palette($foreground, 'disabled-text'),
  'highlighted': #fffaec,
  'info': #ef6c00,
  'info-contrast': white,
  'notice-bg': rgba(black, 0.04),
  'primary': mat.m2-get-color-from-palette($default-theme-primary, 900),
  'score': #FFC107,
  'secondary-text': mat.m2-get-color-from-palette($foreground, 'secondary-text'),
  'selected-bg': mat.m2-get-color-from-palette($default-theme-primary, 50),
  'success': #008A05,
  'success-contrast': black,
  'text': mat.m2-get-color-from-palette($foreground, 'text'),
  'warn': mat.m2-get-color-from-palette($default-theme-warn, 500),
);
