.mat-icon {
  &.mat-18 {
    font-size: 18px;
    width: 18px;
    height: 18px;
    min-width: 0;
  }
  &.mat-24 {
    font-size: 24px;
    width: 24px;
    height: 24px;
    min-width: 0;
  }
  &.mat-30 {
    font-size: 30px;
    width: 30px;
    height: 30px;
    min-width: 0;
  }
  &.mat-36 {
    font-size: 36px;
    width: 36px;
    height: 36px;
    min-width: 0;
  }
  &.mat-40 {
    font-size: 40px;
    width: 40px;
    height: 40px;
    min-width: 0;
  }
  &.mat-48 {
    font-size: 48px;
    width: 48px;
    height: 48px;
    min-width: 0;
  }
}
