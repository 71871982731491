.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

.center {
  text-align: center;
}

.inactive {
  opacity: 0;
  visibility: hidden;
  transition: opacity 250ms, visibilty 250ms;
}

.active {
  opacity: 1;
  visibility: visible;
  transition: opacity 250ms, visibilty 250ms;
}

.more-info {
  border-bottom: 1px dotted;
}
