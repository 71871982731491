@use '@angular/material' as mat;
@use 'style/base/typography';

.mat-mdc-table {
  max-width: 100%;
}

td.mdc-data-table__cell, th.mdc-data-table__header-cell {
  font-size: mat.m2-font-size($wise-typography, 'caption');

  .mdc-form-field {
    font-size: mat.m2-font-size($wise-typography, 'caption');
    max-width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

th.mat-mdc-header-cell {
  font-weight: 500;
}

div.mat-sort-header-content {
  text-align: start;
}
