@use '@angular/material' as mat;
@use 'sass:map';

// define Angular Material theme colors
$dark-primary: (
  50 : #ffffff,
  100 : #e9e9e9,
  200 : #d9d9d9,
  300 : #c4c4c4,
  400 : #9d9d9d,
  500 : #7b7b7b,
  600 : #555555,
  700 : #434343,
  800 : #262626,
  900 : #000000,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
  )
);

$dark-accent: (
  50 : #ffffff,
  100 : #e9e9e9,
  200 : #d9d9d9,
  300 : #c4c4c4,
  400 : #9d9d9d,
  500 : #7b7b7b,
  600 : #555555,
  700 : #434343,
  800 : #262626,
  900 : #000000,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
  )
);

$dark-theme-primary: mat.m2-define-palette($dark-primary, 50);
$dark-theme-accent: mat.m2-define-palette($dark-accent, 50);
$dark-theme-warn: mat.m2-define-palette(mat.$m2-red-palette, 600);

// create Angular Material theme
$dark: mat.m2-define-dark-theme((
  color: (
    primary: $dark-theme-primary,
    accent: $dark-theme-accent,
    warn: $dark-theme-warn,
  ),
  typography: $wise-typography,
));

// custom background and foreground palettes for this theme
$dark-theme-background: (
  status-bar: black,
  app-bar:    black,
  background: black,
);

$dark-theme-foreground: (
  base:              white,
);

$dark-theme: map.deep-merge($dark, (
  'color': (
    'background': $dark-theme-background,
    'foreground': $dark-theme-foreground,
  ),
));

// get theme color variables
$foreground: map.get($dark-theme, foreground);
$background: map.get($dark-theme, background);

// create colors object (including additional colors)
$dark-colors: (
  'accent': mat.m2-get-color-from-palette($dark-theme-accent, 50),
  'accent-1': white,
  'accent-2': white,
  'app-bg': black,
  'control-bg': black,
  'control-bg-active': black,
  'disabled-text': mat.m2-get-color-from-palette($foreground, 'disabled-text'),
  'highlighted': #fffaec,
  'info': #ef6c00,
  'info-contrast': white,
  'notice-bg': rgba(white, 0.04),
  'primary': mat.m2-get-color-from-palette($dark-theme-primary, 500),
  'score': #FFC107,
  'secondary-text': mat.m2-get-color-from-palette($foreground, 'secondary-text'),
  'selected-bg': mat.m2-get-color-from-palette($dark-theme-primary, 50),
  'success': #00C853,
  'success-contrast': black,
  'text': mat.m2-get-color-from-palette($foreground, 'text'),
  'warn': mat.m2-get-color-from-palette($dark-theme-warn, 600),
);
