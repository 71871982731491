@use '@angular/material' as mat;
@use '../base/typography';

.info-block {
  border-radius: $card-border-radius;
  padding: 12px;
  font-size: mat.m2-font-size($wise-typography, 'caption');

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
