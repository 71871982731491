.standalone {
  p:last-child {
    margin-bottom: 0;
  }

  .mat-mdc-card-actions {
    margin: 0;
    padding: 16px 0;

    @media (min-width: breakpoint('sm.min')) {
      padding-top: 24px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.standalone__content {
  max-width: breakpoint('sm.min');
  margin: 16px;

  @media (min-width: breakpoint('sm.min')) {
    margin: 32px auto;
  }
}

.standalone__content--sm {
  max-width: 400px;
}

.standalone__title {
  text-align: center;

  @media (max-width: breakpoint('xs.max')) {
    margin-bottom: 8px;
  }
}

.standalone__error {
  margin: 8px 0;
}
