@use '@angular/material' as mat;
@use '../base/typography';

.notice {
  text-align: center;
  padding: 0.725em;
  margin: 24px auto;
  border-radius: $card-border-radius;
  font-size: mat.m2-font-size($wise-typography, 'caption');

  @media (min-width: breakpoint('sm.min')) {
    max-width: 80%;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

.notice--inline {
  margin: 0;
  max-width: none;
}
