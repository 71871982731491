.section {
  position: relative;
  padding-bottom: 36px;
}

.section--fullwidth {
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: breakpoint('sm.min')) {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.section__header {
  @media (max-width: breakpoint('xs.max')) {
    padding-bottom: 16px;
  }
}

.section__tab {
  padding: 24px 4px;

  @media (min-width: breakpoint('sm.min')) {
    padding: 24px 16px;
  }
}
