@charset 'UTF-8';
@use '@angular/material' as mat;

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions';

// 2. Vendors
//@import
  //'vendor/normalize';

// 3. Base stuff
@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/helpers',
  'abstracts/mixins';

// 4. Layout-related sections
@import
  'layout/content',
  'layout/main',
  'layout/section',
  'layout/standalone';

// 5. Components
@import
  'components/avatar',
  'components/buttons',
  'components/content-block',
  'components/dialog',
  'components/icons',
  'components/inputs',
  'components/lists',
  'components/expansion-panel',
  'components/info-block',
  'components/menu',
  'components/notice',
  'components/table',
  'components/tooltip';

// 6. Page-specific styles
//@import
  //'pages/home';

// 7. Themes

// always include only once per project
@include mat.core();

// material typography
@include mat.typography-hierarchy($wise-typography);

// specify common style class eg: <body class="common-styles"> ... </body>
.common-styles {
  @include common-setup();
}

// import themes
@import 'themes/default';
@import 'themes/dark';
@import 'themes/author';
@import 'themes/monitor';
@import "~@ng-select/ng-select/themes/material.theme.css";

@import "~@ng-select/ng-select/themes/material.theme.css";

@import "~@ng-select/ng-select/themes/material.theme.css";

// TODO: remove/merge after upgrading apps to Angular
@import 'themes/apps';
.app-styles {
  @include apps-setup();
}

$filled-input-bg: rgba(0, 0, 0, 0.04);

// specify custom theme classes eg: <body class="common-styles default-theme"> ... </body>
@include mat.all-component-themes($default-theme);
.default-theme {
  @include theme-setup($default-theme, $default-colors);
  @include filled-input-bg();
}

.author-theme {
  @include mat.all-component-colors($author-theme);
  @include theme-setup($author-theme, $author-colors);
  @include filled-input-bg();
}

.monitor-theme {
  @include mat.all-component-colors($monitor-theme);
  @include theme-setup($monitor-theme, $monitor-colors);
  @include filled-input-bg();
}

.dark-theme {
  @include mat.all-component-colors($dark-theme);
  @include theme-setup($dark-theme, $dark-colors);
  @include filled-input-bg(true);
  color: white;
}
