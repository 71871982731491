.controls {
  padding: 16px 16px 0;
  border-radius: 0;
}

.form-field-long-hint {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-underline {
    position: initial;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-subscript-wrapper {
    position: relative;
  }
}

.form-field-no-label {
  &.mat-form-field-appearance-fill, &.mat-form-field-appearance-outline {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-wrapper {
      margin: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix {
      border-top-width: 0.343em;
    }
  }

  &.mat-form-field-appearance-fill {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix {
      padding: 0 0 1em 0;
    }
  }
}

.form-field-no-hint {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-mdc-form-field.mat-form-field-appearance-fill {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-flex {
    border-radius: $card-border-radius;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-underline {
    &::before {
      max-height: 0;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-ripple {
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
  }
}

.mat-mdc-icon-button.input-control--appearance-fill {
  margin: 6px 0 6px 4px;
}

.form-field-extra-sub {
  margin-bottom: 12px;
}

.checkbox-inline {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-layout {
    display: inline;
    white-space: normal;
  }
}
